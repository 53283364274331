import { FieldNameType, CustomTypes } from '@wix/forms-common'

export interface Field {
  category: string
  subCategory?: string
  value: FieldPreset
  name: string
  customFieldId?: string
  isPremium?: boolean
  dependsOn?: string[]
  tooltip?: string
  crmType?: string
}

export interface MissingField {
  type: FieldNameType
  name: string
}

export type CustomField = {
  name: string
  fieldType: CustomTypes
}

export type CustomFieldResponse = {
  id: string
  name: string
  fieldType: CustomTypes
}

export const CRM_LABEL_MAX_LENGTH = 29
