import { getStaticsBaseUrl, getTranslationByPlugin } from '../../../utils/utils'
import translations from '../../../utils/translations'
import { PanelName, FieldRenderConfigType } from '@wix/forms-common'
import _ from 'lodash'
import { getActivePlugin, isMultiStepPlugin, isRegistrationFormPlugin } from '../plugins/utils'
import MULTI_STEP_HELP_IDS from '../../../plugins/multi-step-form/constants/help-ids'
import { InitialPanelData } from '../../../panels/commons/base-panel'
import { OwnSettingsPanelProps } from '../../../panels/form-settings-panel/components/form-settings-panel'
import { UpgradeAlertPanelOwnProps } from '../../../panels/upgrade-alert-panel/components/upgrade-alert-panel'
import { AddFieldOwnProps } from '../../../panels/add-field-panel/components/add-field-panel'
import { OwnManageStepsPanelPropsProps } from '../../../panels/manage-steps-panel/components/manage-steps-panel'
import { ChangeButtonLabelPanelOwnProps } from '../../../panels/change-button-label-panel/components/change-button-label-panel'
import { ConnectFieldPanelProps } from '../../../panels/connect-field-panel/components/connect-field-panel'
import { FormLayoutPanelOwnProps } from '../../../panels/form-layout-panel/components/form-layout-panel'
import { SubmitSettingsPanelOwnProps } from '../../../panels/submit-settings-panel/components/submit-settings-panel'
import { OwnPaymentWizardPanelProps } from '../../../panels/payment-wizard-panel/components/payment-wizard-panel'
import { ClosePaymentWizardAlertPanelProps } from '../../../panels/close-payment-wizard-alert-panel/components/close-payment-wizard-alert-panel'
import { OwnContactSyncPanelProps } from '../../../panels/contact-sync-panel/components/contact-sync-panel'
import { WarningPanelOwnProps } from '../../../panels/warning-panel/components/warning-panel'

const createUrlFor = msid => panelName =>
  `https://${getStaticsBaseUrl()}/${panelName}-panel.html?msid=${msid}`

export const createPanelDefs = msid => {
  const urlFor = createUrlFor(msid)

  return {
    [PanelName.ADD_FORM]: ({ isResponsive }) => {
      const height = isResponsive ? 265 : 563
      const width = isResponsive ? 520 : 666

      return {
        title: translations.t('addForm.panelTitle'),
        width,
        height,
        helpId: 'e5d2c2d0-8f70-4140-b6ef-613327dbe3a8',
        url: urlFor('add-form'),
      }
    },
    [PanelName.FORM_MANAGE_SUBSCRIBERS]: () => ({
      shouldHideHeader: true,
      width: 744,
      height: 488,
      url: urlFor('manage-subscribers'),
    }),
    [PanelName.NEW_FORM_SETTINGS]: (initialData: InitialPanelData<OwnSettingsPanelProps>) => ({
      title: getTranslationByPlugin({
        t: translations.t,
        prefix: 'formSettings',
        postfix: 'panelTitle',
        plugin: getActivePlugin(initialData.plugins),
      }),
      componentRef: initialData.componentRef,
      initialData,
      width: 437,
      height: 582,
      type: 'settings' as any,
      helpId: isMultiStepPlugin(initialData.plugins)
        ? MULTI_STEP_HELP_IDS.SETTINGS
        : '342ee9a5-b635-4669-bca7-6bfe756c8ab0',
      url: urlFor('form-settings'),
    }),
    [PanelName.MANAGE_STEPS]: (initialData: InitialPanelData<OwnManageStepsPanelPropsProps>) => ({
      title: translations.t('multiStepForm.manageSteps.panelTitle'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: 546,
      url: urlFor('manage-steps'),
      helpId: MULTI_STEP_HELP_IDS.MANAGE_STEPS,
    }),
    [PanelName.CHANGE_BUTTON_LABEL]: (
      initialData: InitialPanelData<ChangeButtonLabelPanelOwnProps>,
    ) => ({
      title: translations.t(`changeButtonLabelPanel.${_.camelCase(initialData.role)}.title`),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: 151,
      url: urlFor('change-button-label'),
      helpId: MULTI_STEP_HELP_IDS.MANAGE_STEPS,
    }),
    [PanelName.CONNECT_FIELD]: (
      initialData: InitialPanelData<ConnectFieldPanelProps>,
      { mode, fieldRenderConfig = {}, experiments },
    ) => {

      const asAdvancedSettings = experiments.enabled(
        'specs.crm.FormsEditorFieldAdvancedSettings',
      )

      return {
        title: asAdvancedSettings
          ? translations.t('advancedSettignsPanel.panelTitle')
          : translations.t('connectFieldPanel.panelTitle'),
        componentRef: initialData.componentRef,
        initialData,
        width: 288,
        height:
          _.get(fieldRenderConfig, 'crmSync', '') === FieldRenderConfigType.REMOVE
            ? asAdvancedSettings
              ? 430
              : 178
            : asAdvancedSettings
            ? 440
            : 300,
        helpId: {
          desktop: 'be46ba62-113a-4fba-9805-fc251858005b',

          mobile: 'dad84a79-8701-408c-9174-454062221932',
        }[mode],
        url: urlFor('connect-field'),
      }
    },
    [PanelName.FORM_LAYOUT]: (
      initialData: InitialPanelData<FormLayoutPanelOwnProps>,
      { mode, isResponsive },
    ) => ({
      title: translations.t('formStyle.layout.panelTitle'),
      componentRef: initialData.componentRef,
      initialData,
      width: 288,
      height: isResponsive ? 203 : 546,
      type: 'layout' as any,
      helpId: {
        desktop: isMultiStepPlugin(initialData.plugins)
          ? MULTI_STEP_HELP_IDS.LAYOUTS
          : '69c55c8d-6865-45ed-ab87-7ce70e1564f9',
        mobile: isMultiStepPlugin(initialData.plugins)
          ? MULTI_STEP_HELP_IDS.LAYOUTS
          : '7a4ec10c-f40f-4ebf-a475-0fbdd3978ad6',
      }[mode],
      url: urlFor('form-layout'),
    }),
    [PanelName.SUBMIT_SETTINGS]: (initialData: InitialPanelData<SubmitSettingsPanelOwnProps>) => {
      const showEnhancedSubmitSettingsPanel = !isRegistrationFormPlugin(initialData.plugins)
      return {
        title: showEnhancedSubmitSettingsPanel
          ? translations.t('submitSettingsPanel.formBuilder.panelTitle')
          : translations.t('buttonChangeText.panelTitle'),
        componentRef: initialData.componentRef,
        initialData,
        width: 288,
        height: showEnhancedSubmitSettingsPanel ? 525 : 100,
        url: urlFor('submit-settings'),
        helpId: '949303cc-13bf-4d95-ba5c-72d2db07b69e',
      }
    },
    [PanelName.ADD_FIELD]: (initialData: InitialPanelData<AddFieldOwnProps>) => ({
      title: translations.t('addField.panelTitle'),
      initialData,
      componentRef: initialData.componentRef,
      width: 430,
      height: 519,
      type: 'add' as any,
      url: urlFor('add-field'),
      helpId: '4681150c-affb-4fbf-a7a9-26fd45557cbf',
    }),
    [PanelName.UPGRADE_ALERT]: (initialData: InitialPanelData<UpgradeAlertPanelOwnProps>) => ({
      shouldHideHeader: true,
      initialData,
      componentRef: initialData.componentRef,
      width: 564,
      height: 320,
      url: urlFor('upgrade-alert'),
    }),
    [PanelName.PUBLISH_SITE_POP_UP]: (componentRef: ComponentRef) => ({
      title: translations.t('publishSite.panelTitle'),
      initialData: {
        componentRef,
      },
      width: 500,
      height: 247,
      url: urlFor('publish-site'),
    }),
    [PanelName.PAYMENT_WIZARD]: (initialData: InitialPanelData<OwnPaymentWizardPanelProps>) => ({
      shouldHideHeader: true,
      initialData,
      width: 614,
      height: 396,
      url: urlFor('payment-wizard'),
    }),
    [PanelName.CLOSE_PAYMENT_WIZARD_ALERT]: (
      initialData: InitialPanelData<ClosePaymentWizardAlertPanelProps>,
    ) => ({
      shouldHideHeader: true,
      componentRef: initialData.componentRef,
      initialData,
      width: 500,
      height: 247,
      url: urlFor('close-payment-wizard-alert'),
    }),
    [PanelName.CONTACT_SYNC_PANEL]: (initialData: InitialPanelData<OwnContactSyncPanelProps>) => ({
      title: translations.t('contactSync.panelTitle'),
      componentRef: initialData.componentRef,
      initialData,
      width: 504,
      height: 642,
      url: urlFor('contact-sync'),
    }),
    [PanelName.WARNING]: (initialData: InitialPanelData<WarningPanelOwnProps>) => {
      let extraHeight = 0

      _.forEach(initialData.bullets, bullet => {
        if (translations.t(bullet).length > 75) {
          extraHeight += 18
        }
      })

      return {
        initialData,
        width: 560,
        height: initialData.height + extraHeight,
        shouldHideHeader: true,
        url: urlFor('warning'),
      }
    },
  }
}
