import { initBiLoggerForEditorApp } from '../../utils/bi'
import { EVENTS, ORIGINS, PANEL_NAMES } from '../../constants/bi'
import { GFPP, GFPP_IDS } from '../core/manifests/manifest-commons'
import { editorAppMetaData } from './editor-app'
import { PanelName } from '@wix/forms-common'
import { getPrimaryConnection } from '../core/utils'
import { FormsFieldPreset } from '@wix/forms-common'
import _ from 'lodash'
import CoreApi from '../core/core-api'
import {
  WidgetGfppClickedEvent,
  ComponentGfppClickedEvent,
  ComponentDeletedEvent,
  SiteWasFirstSaved,
  GlobalDesignPresetChangedEvent,
  WidgetPastedEvent,
  ConnectedComponentPastedEvent,
  StateChangedEvent,
  WidgetDuplicatedEvent,
  ConnectedComponentDuplicatedEvent,
  PlatformEvent,
  EditorPlatformApp,
  ComponentDataChanged,
} from '@wix/platform-editor-sdk'
import { OwnSettingsPanelProps } from '../../panels/form-settings-panel/components/form-settings-panel'
import { handleError, updateMetaSiteId } from './monitoring'

const handleGfppClickedImpl = async ({
  componentRef,
  id,
  api,
}: {
  componentRef: ComponentRef
  id: string
  api: CoreApi
}) => {
  const onFieldSettingsOpenEvent = (evid) => ({ fieldType, crmLabel, formId }) =>
    api.log({
      evid,
      field_type: fieldType,
      field_name: crmLabel,
      origin: ORIGINS.GFPP,
      form_comp_id: formId,
    })

  switch (id) {
    case GFPP_IDS.NEW_FORM_SETTINGS:
      api.managePanels.openComponentPanel<OwnSettingsPanelProps>(
        componentRef,
        PanelName.NEW_FORM_SETTINGS,
        ({ preset, formId }) =>
          api.log({
            evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL,
            template: preset,
            form_comp_id: formId,
          }),
      )
      break
    case GFPP_IDS.ADD_FIELD:
      api.managePanels.openComponentPanel(componentRef, PanelName.ADD_FIELD, ({ preset, formId }) =>
        api.log({
          evid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        }),
      )
      break
    case GFPP_IDS.MANAGE_SUBSCRIBERS:
      api.managePanels.openModalPanel(componentRef, PanelName.FORM_MANAGE_SUBSCRIBERS, () =>
        api.log({
          evid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL,
          action: 'Manage Subscribers',
        }),
      )
      break
    case GFPP_IDS.CONNECT_FIELD:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.CONNECT_FIELD,
        onFieldSettingsOpenEvent(EVENTS.PANELS.connectFieldPanel.OPEN_PANEL),
      )
      break
    case GFPP_IDS.DYNAMIC_FIELD_SETTINGS:
      api.managePanels.openDynamicFieldSettingsPanel(
        componentRef,
        onFieldSettingsOpenEvent(EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL),
      )
      break
    case GFPP_IDS.FORM_LAYOUT:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.FORM_LAYOUT,
        ({ columns, formId }) =>
          api.log({
            evid: EVENTS.PANELS.formLayoutPanel.OPEN_PANEL,
            action: 'open',
            layout: columns === 1 ? 'single' : columns ? `${columns} column` : '2 column',
            form_comp_id: formId,
          }),
      )
      break
    case GFPP_IDS.SUBMIT_SETTINGS:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.SUBMIT_SETTINGS,
        ({ preset, formId }) =>
          api.log({
            evid: EVENTS.PANELS.submitSettingsPanel.OPEN_PANEL,
            template: preset,
            form_comp_id: formId,
          }),
      )
      break
    case GFPP_IDS.RECAPTCHA_HELP:
      api.managePanels.openHelpPanel(GFPP.HELP_ID.CAPTCHA, () =>
        api.log({
          evid: EVENTS.PANELS.recaptchaHelp.OPEN_PANEL,
          field_group: 'custom',
          field_name: FormsFieldPreset.GENERAL_RECAPTCHA,
          field_type: 'Captcha',
        }),
      )
      break
    case GFPP_IDS.MANAGE_STEPS:
      api.managePanels.openComponentPanel(componentRef, PanelName.MANAGE_STEPS, ({ formId }) =>
        api.log({
          evid: EVENTS.PANELS.manageSteps.OPEN_PANEL,
          click_type: 'manage steps button',
          form_comp_id: formId,
        }),
      )
      break
    case GFPP_IDS.CHANGE_BUTTON_LABEL:
      const componentConnection = await api.getComponentConnection(componentRef)
      const role = _.get(componentConnection, 'role')
      api.managePanels.openComponentPanel(componentRef, PanelName.CHANGE_BUTTON_LABEL, _.noop, {
        role,
      })
      break
    default:
      break
  }
}

const handleAppWidgetGfppClicked = async ({
  componentRef,
  id,
}: WidgetGfppClickedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  const formContainer = await api.getFormContainerOfAppWidget(componentRef)
  return handleGfppClickedImpl({ componentRef: formContainer, id, api })
}

const handleGfppClicked = async ({
  componentRef,
  id,
}: ComponentGfppClickedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  return handleGfppClickedImpl({ componentRef, id, api })
}

const handleComponentDelete = async ({
  componentRef,
  connections,
}: ComponentDeletedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  const connection = getPrimaryConnection(connections)
  await api.handleDelete(componentRef, connection)
}

const handleComponentDataChanged = async ({ compRef }: ComponentDataChanged['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.handleDataChanged(compRef)
}

const handlePublish = async () => {
  const api = await editorAppMetaData.getCoreApi()
  api.interactionStarted('publish-site')
  await api.sendAllFormsData()
  api.interactionEnded('publish-site')
}

const handleFirstSave = async (eventPayload: SiteWasFirstSaved['eventPayload'] | {}) => {
  const api: CoreApi = await editorAppMetaData.getCoreApi()
  const newMetaSiteId: string = _.get(eventPayload, 'metaSiteId')

  if (newMetaSiteId) {
    updateMetaSiteId(newMetaSiteId)
    const biLogger = await initBiLoggerForEditorApp(newMetaSiteId, api.originEditorType())
    api.setBiLogger(biLogger)
  }

  await api.reportBiFirstSave()

  const allFormsRefsAndConnections = await api.getAllFormsRefsAndConfigs()
  await api.createMissingFormLabels(allFormsRefsAndConnections)
}

const handlePresetChanged = async ({
  componentRef,
  preset,
}: GlobalDesignPresetChangedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  const esi = await api.getEditorSessionId()
  const formRef = (await api.isAppWidget(componentRef))
    ? await api.getFormContainerOfAppWidget(componentRef)
    : componentRef

  const biData = {
    startBi: {
      form_comp_id: await api.getFormId(formRef),
      panel_name: PANEL_NAMES.formStylePanel,
      control_name: 'main',
      value: preset,
      esi,
    },
  }

  await api.style.updateTheme(formRef, preset, biData)
}

const handleAppWidgetPasted = async ({
  componentRef: controllerRef,
}: WidgetPastedEvent['eventPayload'] | WidgetDuplicatedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.addForm.handleDuplicatedForm({ controllerRef })
}

const handleConnectedComponentPasted = async ({
  componentRef,
}:
  | ConnectedComponentPastedEvent['eventPayload']
  | ConnectedComponentDuplicatedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.fields.onDuplicateField(componentRef)
}

const handleStateChanged = async ({ componentRef }: StateChangedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.steps.onStateChanged({ componentRef })
}

const handlePageDuplicated = async ({ duplicatedPageRef }) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.handleDuplicatedPage({ pageRef: duplicatedPageRef })
}

export const onEvent: EditorPlatformApp['onEvent'] = async ({
  eventPayload,
  eventType,
}: PlatformEvent) => {
  try {
    switch (eventType) {
      case 'widgetGfppClicked':
        await handleAppWidgetGfppClicked(eventPayload)
        break
      case 'componentGfppClicked':
        await handleGfppClicked(eventPayload)
        break
      case 'componentDeleted':
        await handleComponentDelete(eventPayload)
        break
      case 'componentDataChanged':
        await handleComponentDataChanged(eventPayload)
        break
      case 'siteWasPublished':
        await handlePublish()
        break
      case 'pageDuplicated':
        await handlePageDuplicated(eventPayload)
        break
      case 'siteWasFirstSaved':
        await handleFirstSave(eventPayload || {})
        break
      case 'globalDesignPresetChanged':
        await handlePresetChanged(eventPayload)
        break
      case 'widgetPasted':
        await handleAppWidgetPasted(eventPayload)
        break
      case 'widgetDuplicated':
        await handleAppWidgetPasted(eventPayload)
        break
      case 'connectedComponentPasted':
        await handleConnectedComponentPasted(eventPayload)
        break
      case 'connectedComponentDuplicated':
        await handleConnectedComponentPasted(eventPayload)
        break
      case 'stateChanged':
        await handleStateChanged(eventPayload)
        break
      default:
        break
    }
  } catch (error) {
    handleError(error, {
      extra: { eventType, eventPayload },
    })
  }
}
