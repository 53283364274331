import { FormsFieldPreset, FormPlugin } from '@wix/forms-common'
import { GeneralDatePicker } from '../../general-fields/definitions/general-date-picker'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'

export class Anniversary extends GeneralDatePicker {
  public get customFields() {
    return this.base.customFields
  }

  public get crmType() {
    return CRM_TYPES.DATE
  }

  public get crmTag() {
    return CRM_TAGS.ANNIVERSARY
  }

  public get fieldType() {
    return FormsFieldPreset.ANNIVERSARY
  }

  protected get data(): any {
    return { placeholder: this.translate(`fieldTypes.${FormsFieldPreset.ANNIVERSARY}`) }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.recommended }
      },
      [FormPlugin.REGISTRATION_FORM]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.none }
      }
    }
  }
}
