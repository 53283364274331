import { ExtendApi } from './extend-api'
import translations from '../../../utils/translations'
import CoreApi from '../core-api'
import { EVENTS } from '../../../constants/bi'
import { PanelName } from '@wix/forms-common'
import { ACTION_TYPE } from './action-types'
import { CUSTOM_ACTION_CLICKED } from './event-types'

const MultiStepFormApi = (coreApi: CoreApi): Partial<ExtendApi> => {
  const onCustomActionClicked = (eventPayload) => {
    const { action, formId, editorType, origin } = eventPayload

    switch (action.panelName) {
      case 'manageSteps':
        coreApi.log({
          evid: EVENTS.PANELS[PanelName.MANAGE_STEPS].OPEN_PANEL,
          form_comp_id: formId,
          click_type: origin,
          builderOrigin: editorType,
        })
        break
    }
  }

  const onEvent = ({ eventType, eventPayload }) => {
    switch (eventType) {
      case CUSTOM_ACTION_CLICKED:
        onCustomActionClicked(eventPayload)
        break
    }
  }

  return {
    onEvent,
    appConfig: () => ({
      features: {
        layout: {
          chooseLayout: false,
        },
        rules: false
      },
      mainCTA: {
        actions: [
          {
            type: ACTION_TYPE.OPEN_COMPONENT_PANEL,
            panelName: 'manageSteps',
            text: translations.t('formSettings.mainTab.MultiStepForm.mainActionButtonText'),
          },
        ],
      },
    }),
  }
}

export default MultiStepFormApi
