import { GFPP_IDS, GFPP_ACTIONS, GFPP } from './manifest-commons'
import translations from '../../../utils/translations'
import MULTI_STEP_HELP_IDS from '../../../plugins/multi-step-form/constants/help-ids'
import { createMultiStepGlobalDesignManifest, roleDesignMapping } from './global-design-manifest'
import { getWidgetDesignManifest } from './widget-design-manifest'
import { STEP_ROLE, THANK_YOU_STEP_ROLE, ROLE_FORM } from '../../../constants/roles'
import { AppStateObject } from '../app-state/app-state-builder'

export const createMultiStepManifest = ({
  isTopPremium,
  withMultiStepLayoutPanel,
  withWidgetDesign,
}: AppStateObject = {}) => {
  return {
    box: {
      showFocusBox: true,
      displayName: translations.t('formBuilder'),
      behavior: { duplicatable: true },
      gfpp: {
        desktop: {
          helpId: MULTI_STEP_HELP_IDS.GFPP,
          mainAction1: {
            actionId: GFPP_IDS.NEW_FORM_SETTINGS,
            label: translations.t('formSettings.gfppTitle'),
          },
          mainAction2: {
            actionId: GFPP_IDS.MANAGE_STEPS,
            label: translations.t('multiStepForm.manageSteps.gfppTitle'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
            [GFPP_ACTIONS.ADD]: { actionId: GFPP_IDS.ADD_FIELD },
            [GFPP_ACTIONS.LAYOUT]: withMultiStepLayoutPanel && { actionId: GFPP_IDS.FORM_LAYOUT },
            ...(withMultiStepLayoutPanel
              ? { [GFPP_ACTIONS.LAYOUT]: { actionId: GFPP_IDS.FORM_LAYOUT } }
              : {}),
            ...(isTopPremium ? {} : { [GFPP_ACTIONS.UPGRADE]: GFPP.KEEP_DEFAULT }),
          },
          ...(withWidgetDesign ? {} : { globalDesign: createMultiStepGlobalDesignManifest() }),
          ...(withWidgetDesign ? { widgetDesign: getWidgetDesignManifest() } : {}),
        },
        mobile: {
          /* use default configurations */
          helpId: MULTI_STEP_HELP_IDS.MOBILE_GFPP,
        },
      },
      designMapping: !withWidgetDesign && roleDesignMapping[ROLE_FORM],
    },
    steps: {
      [STEP_ROLE]: {
        designMapping: !withWidgetDesign && roleDesignMapping[STEP_ROLE],
        relatedToRole: ROLE_FORM,
      },
      [THANK_YOU_STEP_ROLE]: {
        designMapping: !withWidgetDesign && roleDesignMapping[THANK_YOU_STEP_ROLE],
        relatedToRole: ROLE_FORM,
      },
    },
  }
}
